import { auth } from "../../database/utils";
import { takeLatest, put, all, call } from "redux-saga/effects";
import { setUsers, setUser, fetchUsersStart } from "./users.actions";
import {
  handleAddUser,
  handleFetchUsers,
  handleFetchUser,
  handleDeleteUser,
} from "./users.helpers";
import usersTypes from "./users.types";

export function* addUser({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddUser({
      ...payload,
      userID: auth.currentUser.uid,
      userEmail: auth.currentUser.email,
      createdDate: timestamp,
    });
    yield put(fetchUsersStart());
  } catch (err) {}
}

export function* onAddUserStart() {
  yield takeLatest(usersTypes.ADD_NEW_USER_START, addUser);
}

export function* fetchUsers({ payload }) {
  try {
    const users = yield handleFetchUsers(payload);
    yield put(setUsers(users));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchUsersStart() {
  yield takeLatest(usersTypes.FETCH_USERS_START, fetchUsers);
}

export function* fetchUser({ payload }) {
  try {
    const user = yield handleFetchUser(payload);
    yield put(setUser(user));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchUserStart() {
  yield takeLatest(usersTypes.FETCH_USER_START, fetchUser);
}

export function* onDeleteUserStart() {
  yield takeLatest(usersTypes.DELETE_USER_START, deleteUser);
}

export function* deleteUser({ payload }) {
  try {
    yield handleDeleteUser(payload);
    yield put(fetchUsersStart());
  } catch (err) {
    // console.log(err);
  }
}

export default function* usersSagas() {
  yield all([
    call(onAddUserStart),
    call(onFetchUsersStart),
    call(onDeleteUserStart),
    call(onFetchUserStart),
  ]);
}
