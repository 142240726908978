import userTypes from "./user.types";

export const emailVerificationStart = (userCredentials) => ({
  type: userTypes.EMAIL_VERIFICATION_START,
  payload: userCredentials,
});

export const verifyEmailStart = (oobCode) => ({
  type: userTypes.VERIFY_EMAIL_START,
  payload: oobCode,
});

export const emailVerificationSuccess = () => ({
  type: userTypes.EMAIL_VERIFICATION_SUCCESS,
});

export const emailVerificationError = (error) => ({
  type: userTypes.EMAIL_VERIFICATION_ERROR,
  payload: error,
});

export const emailSignInStart = (userCredentials) => ({
  type: userTypes.SIGN_IN_START,
  payload: userCredentials,
});

export const signInSuccess = (user) => ({
  type: userTypes.SIGN_IN_SUCCESS,
  payload: user,
});

export const signInError = (error) => ({
  type: userTypes.SIGN_IN_ERROR,
  payload: error,
});

export const setLoading = (loading) => ({
  type: userTypes.SET_LOADING,
  payload: loading,
});

export const checkUserSession = () => ({
  type: userTypes.CHECK_USER_SESSION,
});

export const signOutUserStart = () => ({
  type: userTypes.SIGN_OUT_USER_START,
});

export const signOutUserSuccess = () => ({
  type: userTypes.SIGN_OUT_USER_SUCCESS,
});

export const signUpUserStart = (userCredentials) => ({
  type: userTypes.SIGN_UP_USER_START,
  payload: userCredentials,
});

export const signUpUserSuccess = ({ user, additionalData }) => ({
  type: userTypes.SIGN_UP_USER_SUCCESS,
  payload: { user, additionalData },
});

export const signUpUserError = (error) => ({
  type: userTypes.SIGN_UP_USER_ERROR,
  payload: error,
});

export const userError = (err) => ({
  type: userTypes.USER_ERROR,
  payload: err,
});

export const resetUserError = () => ({
  type: userTypes.RESET_USER_ERROR,
});

export const resetPasswordStart = (userCredentials) => ({
  type: userTypes.RESET_PASSWORD_START,
  payload: userCredentials,
});

export const resetPasswordSuccess = () => ({
  type: userTypes.RESET_PASSWORD_SUCCESS,
  payload: true,
});
export const resetUserState = () => ({
  type: userTypes.RESET_USER_STATE,
});
//GOOGLE LOGIN
export const googleSignInStart = () => ({
  type: userTypes.GOOGLE_SIGN_IN_START,
});
//FACEBOOK LOGIN
export const facebookSignInStart = () => ({
  type: userTypes.FACEBOOK_SIGN_IN_START,
});
//UPDATE USER LOCATION
export const updateUserLocationStart = (user) => ({
  type: userTypes.UPDATE_USER_LOCATION_START,
  payload: user,
});
//UPDATE USER PROFILE
export const updateUserProfileStart = (user) => ({
  type: userTypes.UPDATE_USER_PROFILE_START,
  payload: user,
});
//UPDATE BLOCK LIST
export const updateBlockListStart = (userID, targetID) => ({
  type: userTypes.UPDATE_BLOCK_LIST_START,
  payload: { userID, targetID },
});

//ADD USER TO FAVORITES
export const saveUserStart = (favUserID) => ({
  type: userTypes.SAVE_USER_START,
  payload: { favUserID },
});

export const saveUserSuccess = (favUser) => ({
  type: userTypes.SAVE_USER_SUCCESS,
  payload: favUser,
});

export const saveUserFailure = (error) => ({
  type: userTypes.SAVE_USER_ERROR,
  payload: error,
});

//REMOVE USER FROM FAVORITES
export const removeUserStart = (favUserID) => ({
  type: userTypes.REMOVE_USER_START,
  payload: favUserID,
});

export const removeUserSuccess = (favUserID) => ({
  type: userTypes.REMOVE_USER_SUCCESS,
  payload: favUserID,
});

export const removeUserFailure = (error) => ({
  type: userTypes.REMOVE_USER_ERROR,
  payload: error,
});
