import React, { useState } from "react";
// import React, { useEffect, useState } from "react";
//DATABASE
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
//COMPONENTS
import Routing from "./Routing";
//NOTIFICATION
// import { getToken, onMessageListener } from "./firebase-messaging-sw.js";
import { Geolocator } from "./components/Geolocator.jsx";
import { ModalAge } from "./components/Modal/Modals.jsx";
import { Button } from "./components/Button/Buttons.jsx";
// import DeviceDetector from "./DeviceDetector.jsx";

const currentUserSelector = (state) => state.user.currentUser;
const mapState = createSelector([currentUserSelector], (currentUser) => ({
  currentUser,
}));

// export const Notifications = (props) => {
//   const [isTokenFound, setTokenFound] = useState(false);
//   const [token, setToken] = useState(null);

//   useEffect(() => {
//     async function fetchToken() {
//       const data = await getToken();
//       if (data) {
//         setToken(data);
//       }
//     }

//     fetchToken();
//   }, []);

//   useEffect(() => {
//     let data;
//     async function tokenFunc() {
//       data = await getToken(setTokenFound);
//       if (data) {
//         // console.log("Token is", data);
//       }
//       return data;
//     }

//     tokenFunc();
//   }, [setTokenFound]);

//   return <></>;
// };

function App() {
  const { currentUser } = useSelector(mapState);

  const isAgeVerified = localStorage.getItem("validAge") === "true";

  //MODAL
  const [hideModalAge, setHideModalAge] = useState(
    !isAgeVerified ? false : true
  );
  const toggleModalAge = () => setHideModalAge(!hideModalAge);
  const configModal = {
    hideModalAge,
    toggleModalAge,
  };

  const handleAgeCheck = () => {
    localStorage.setItem("validAge", "true");
    toggleModalAge();
  };

  return (
    <>
      <Geolocator currentUser={currentUser} />
      <Routing />
      {/* <Notifications /> */}
      {!isAgeVerified && (
        <>
          {!currentUser && (
            <ModalAge {...configModal}>
              <div>
                <h3>
                  Welcome to <span className="logo">Lovers</span>
                </h3>
                <br />
                <p>
                  To access our platform you must be 18 years old or above.
                  Please select to continue.
                </p>
                <br />
                <Button
                  title="I'm over 18"
                  hierarchy="primary"
                  handleClick={handleAgeCheck}
                />
                <a href="https://google.com">
                  <Button title="I'm under 18" hierarchy="secondary" />
                </a>
              </div>
            </ModalAge>
          )}
        </>
      )}
    </>
  );
}

export default App;
