const userTypes = {
  SET_LOADING: "SET_LOADING",
  CHECK_USER_SESSION: "CHECK_USER_SESSION",

  USER_ERROR: "USER_ERROR",
  RESET_USER_ERROR: "RESET_USER_ERROR",
  RESET_USER_STATE: "RESET_USER_STATE",

  EMAIL_VERIFICATION_START: "EMAIL_VERIFICATION_START",
  EMAIL_VERIFICATION_SUCCESS: "EMAIL_VERIFICATION_SUCCESS",
  EMAIL_VERIFICATION_ERROR: "EMAIL_VERIFICATION_ERROR",
  EMAIL_VERIFICATION_SENT: "EMAIL_VERIFICATION_SENT",
  VERIFY_EMAIL_START: "VERIFY_EMAIL_START",

  SIGN_IN_START: "SIGN_IN_START",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_ERROR: "SIGN_IN_ERROR",

  SIGN_UP_USER_START: "SIGN_UP_USER_START",
  SIGN_UP_USER_SUCCESS: "SIGN_UP_USER_SUCCESS",
  SIGN_UP_USER_ERROR: "SIGN_UP_USER_ERROR",

  SIGN_OUT_USER_START: "SIGN_OUT_USER_START",
  SIGN_OUT_USER_SUCCESS: "SIGN_OUT_USER_SUCCESS",
  SIGN_OUT_USER_ERROR: "SIGN_OUT_USER_ERROR",

  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",

  GOOGLE_SIGN_IN_START: "GOOGLE_SIGN_IN_START",
  GOOGLE_SIGN_IN_SUCCESS: "GOOGLE_SIGN_IN_SUCCESS",
  GOOGLE_SIGN_IN_ERROR: "GOOGLE_SIGN_IN_ERROR",

  FACEBOOK_SIGN_IN_START: "FACEBOOK_SIGN_IN_START",
  FACEBOOK_SIGN_IN_SUCCESS: "FACEBOOK_SIGN_IN_SUCCESS",
  FACEBOOK_SIGN_IN_ERROR: "FACEBOOK_SIGN_IN_ERROR",

  UPDATE_USER_PROFILE_START: "UPDATE_USER_PROFILE_START",
  UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
  UPDATE_USER_PROFILE_ERROR: "UPDATE_USER_PROFILE_ERROR",

  UPDATE_USER_LOCATION_START: "UPDATE_USER_LOCATION_START",
  UPDATE_USER_LOCATION_SUCCESS: "UPDATE_USER_LOCATION_SUCCESS",
  UPDATE_USER_LOCATION_ERROR: "UPDATE_USER_LOCATION_ERROR",

  UPDATE_BLOCK_LIST_START: "UPDATE_BLOCK_LIST_START",
  UPDATE_BLOCK_LIST_SUCCESS: "UPDATE_BLOCK_LIST_SUCCESS",
  UPDATE_BLOCK_LIST_ERROR: "UPDATE_BLOCK_LIST_ERROR",

  SAVE_USER_START: "SAVE_USER_START",
  SAVE_USER_SUCCESS: "SAVE_USER_SUCCESS",
  SAVE_USER_ERROR: "SAVE_USER_ERROR",

  REMOVE_USER_START: "REMOVE_USER_START",
  REMOVE_USER_SUCCESS: "REMOVE_USER_SUCCESS",
  REMOVE_USER_ERROR: "REMOVE_USER_ERROR",
};

export default userTypes;
