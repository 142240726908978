import React, { useEffect } from "react";
//DATABASE
import { useDispatch, useSelector } from "react-redux";
import { fetchMessagesStart } from "../../redux/messages/messages.actions";
import { fetchNotificationsStart } from "../../redux/notifications/notifications.actions";
import { createSelector } from "reselect";

const currentUserSelector = (state) => state.user.currentUser;
const messagesSelector = (state) => state.messagesData.messages;
const notificationsSelector = (state) => state.notificationsData.notifications;
const usersSelector = (state) => state.usersData.users;

const mapState = createSelector(
  [messagesSelector, notificationsSelector, currentUserSelector, usersSelector],
  (messages, notifications, currentUser, users) => ({
    messages,
    notifications,
    currentUser,
    users,
  })
);

export function MessagesBullet({ extendedClass }) {
  const dispatch = useDispatch();
  const { currentUser, messages, users } = useSelector(mapState);
  const { data: messagesData } = messages;
  const { data: usersData } = users;

  useEffect(() => {
    dispatch(fetchMessagesStart());
  }, [dispatch]);

  // CHECK FOR ANY UNVIEWED MESSAGES
  const hasUnviewedMessages = Array.isArray(messagesData)
    ? messagesData.some((message) =>
        Array.isArray(message.messages)
          ? message.messages.some(
              (message) =>
                !message.isViewed &&
                message.senderId !== currentUser.id &&
                message.receiverId === currentUser.id
            )
          : false
      )
    : false;

  return (
    <>
      {hasUnviewedMessages && (
        <div
          className={`notificationsBullet${
            extendedClass ? ` ${extendedClass}` : ""
          }`}
        />
      )}
    </>
  );
}

export function NotificationsBullet({ extendedClass }) {
  const dispatch = useDispatch();
  const { currentUser, notifications, users } = useSelector(mapState);
  const { data: notificationsData } = notifications;
  const { data: usersData } = users;

  useEffect(() => {
    dispatch(fetchNotificationsStart());
  }, [dispatch]);

  // CHECK FOR ANY UNVIEWED MESSAGES
  const hasUnviewedMessages = Array.isArray(notificationsData)
    ? notificationsData.some((notification) =>
        Array.isArray(notifications.notification)
          ? notification.notification.some(
              (notification) =>
                !notification.isViewed &&
                notification.senderId !== currentUser.id
            )
          : false
      )
    : false;

  return (
    <>
      {hasUnviewedMessages && (
        <div
          className={`notificationsBullet${
            extendedClass ? ` ${extendedClass}` : ""
          }`}
        />
      )}
    </>
  );
}
