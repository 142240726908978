import React, { Suspense, lazy, useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
//DATABASE
import { fetchUsersStart } from "./redux/users/users.actions";
//HOC
import { WithAuth } from "./hooks/hoc";
//ROUTING
import { Route, Routes } from "react-router-dom";
//COMPONENTS
import { Layout } from "./components/Layout/Layouts";
import { Loader } from "./components/Loader/Loader";
import { ModalPayment } from "./components/Modal/Modals";
//STATE
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
//INTERNATIONALIZATION
import { useTranslation } from "react-i18next";
//STYLES
import "./defaultStyles.scss";

const Homepage = lazy(() => import("./pages/Homepage"));
const Register = lazy(() => import("./pages/Auth/Register"));
const Login = lazy(() => import("./pages/Auth/Login"));
const Account = lazy(() => import("./pages/Account/Account"));
const AdminDashboard = lazy(() =>
  import("./pages/Account/Admin/AdminDashboard")
);
const Notifications = lazy(() => import("./pages/Inbox/Notifications"));
const Messages = lazy(() => import("./pages/Inbox/Messages"));
const Favorites = lazy(() => import("./pages/Favorites/Favorites"));
const Settings = lazy(() => import("./pages/Account/Settings"));
const Legal = lazy(() => import("./pages/Account/TermsAndPrivacy/Legal"));
const CompleteLoverProfile = lazy(() =>
  import("./pages/Account/CompleteLoverProfile")
);
const BecomeLover = lazy(() => import("./pages/Account/BecomeLover"));
const AccountPreferences = lazy(() =>
  import("./components/AccountPreferences")
);
const TermsOfUse = lazy(() =>
  import("./pages/Account/TermsAndPrivacy/TermsOfUse")
);
const PrivacyPolicy = lazy(() =>
  import("./pages/Account/TermsAndPrivacy/PrivacyPolicy")
);
const CookiePolicy = lazy(() =>
  import("./pages/Account/TermsAndPrivacy/CookiePolicy")
);
const LegalNotice = lazy(() =>
  import("./pages/Account/TermsAndPrivacy/LegalNotice")
);
const FAQ = lazy(() => import("./pages/Account/Support/FAQ"));
const NotificationSettings = lazy(() =>
  import("./pages/Account/Settings/NotificationSettings")
);
const PrivacyAndSecurity = lazy(() =>
  import("./pages/Account/Settings/PrivacyAndSecurity")
);
const About = lazy(() => import("./pages/About"));
const UserPage = lazy(() => import("./pages/Users/UserPage"));
const RestrictedRoute = lazy(() => import("./pages/RestrictedRoute"));
const EmailVerification = lazy(() => import("./pages/Auth/EmailVerification"));
const Page404 = lazy(() => import("./pages/Page404"));

const userErrSelector = (state) => state.user.userErr;
const currentUserSelector = (state) => state.user.currentUser;
const usersSelector = (state) => state.usersData.users;

const mapState = createSelector(
  [userErrSelector, currentUserSelector, usersSelector],
  (userErr, currentUser, users) => ({
    userErr,
    currentUser,
    users,
  })
);

export default function Routing() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //CHECK USERS AND POSTS
  const { userErr, currentUser, users } = useSelector(mapState);
  const { data: usersData } = users;

  useEffect(() => {
    dispatch(fetchUsersStart());
  }, [dispatch]);

  const handlePaymentSuccess = () => {
    toggleModalPayment();
  };
  const handleEditSuccess = () => {
    toggleModalUpdate();
  };

  //MODAL
  const [hideModalPayment, setHideModalPayment] = useState(true);
  const toggleModalPayment = () => setHideModalPayment(!hideModalPayment);
  const [hideModalUpdate, setHideModalUpdate] = useState(true);
  const toggleModalUpdate = () => setHideModalUpdate(!hideModalUpdate);
  const configModal = {
    hideModalPayment,
    toggleModalPayment,
    hideModalUpdate,
    toggleModalUpdate,
  };

  //SWITCH THEME
  const initialTheme = localStorage.getItem("theme") || "dark";
  const [theme] = useState(initialTheme);
  useEffect(() => {
    const root = document.documentElement;
    root.classList.toggle("lightTheme", theme === "light");
  }, [theme]);

  return (
    <>
      <Suspense fallback={<Loader full={true} />}>
        <HelmetProvider>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Layout
                  title={currentUser && <h1 className="logo page">Lovers</h1>}
                  pagePadding="horizontal"
                  fullHeader={true}
                  filters={true}
                >
                  <Homepage />
                </Layout>
              }
            />
            <Route
              path="/get-started"
              element={
                <Layout
                  title={<strong>{t("Register")}</strong>}
                  btnBack={true}
                  pagePadding={true}
                >
                  <Register
                    currentUser={currentUser}
                    usersData={usersData}
                    userErr={userErr}
                  />
                </Layout>
              }
            />
            <Route
              path="/get-started/:inviteToken"
              element={
                <Layout
                  title={<strong>{t("Register")}</strong>}
                  btnBack={true}
                  pagePadding={true}
                >
                  <Register
                    currentUser={currentUser}
                    usersData={usersData}
                    userErr={userErr}
                  />
                </Layout>
              }
            />
            <Route path="/user/:userID" element={<UserPage />} />
            <Route
              path="/verify-email"
              element={
                <Layout
                  title={<strong>{t("Email verification")}</strong>}
                  btnBack={true}
                  pagePadding={true}
                >
                  <EmailVerification currentUser={currentUser} />
                </Layout>
              }
            />
            <Route
              path="/login"
              element={
                <Layout
                  title={<strong>{t("Login")}</strong>}
                  btnBack={true}
                  pagePadding={true}
                >
                  <Login />
                </Layout>
              }
            />
            <Route
              path="/favorites"
              element={
                <Layout
                  title={<h1 className="logo page">{t("Favorites")}</h1>}
                  fullHeader={true}
                >
                  <WithAuth>
                    <Favorites currentUser={currentUser} />
                  </WithAuth>
                </Layout>
              }
            />
            <Route
              path="/messages"
              element={
                <WithAuth>
                  <Layout
                    title={<h1 className="logo page">{t("Messages")}</h1>}
                    fullHeader={true}
                  >
                    <Messages currentUser={currentUser} />
                  </Layout>
                </WithAuth>
              }
            />
            <Route
              path="/notifications"
              element={
                <Layout
                  title={<h1 className="logo page">{t("Notifications")}</h1>}
                  fullHeader={true}
                >
                  <WithAuth>
                    <Notifications currentUser={currentUser} />
                  </WithAuth>
                </Layout>
              }
            />
            <Route
              path="/account"
              element={
                <Layout
                  title={
                    currentUser && <h1 className="logo page">{t("Account")}</h1>
                  }
                  pagePadding={true}
                  fullHeader={true}
                  // filters={true}
                >
                  <WithAuth>
                    <Account />
                  </WithAuth>
                </Layout>
              }
            />
            <Route
              path="/account/admin-dashboard"
              element={
                <Layout
                  title={
                    currentUser && (
                      <h1 className="logo page">{t("Admin dashboard")}</h1>
                    )
                  }
                  pagePadding={true}
                  fullHeader={true}
                >
                  <WithAuth>
                    <AdminDashboard />
                  </WithAuth>
                </Layout>
              }
            />
            <Route
              path="/account/become-lover"
              element={
                <Layout
                  title={<strong>{t("Become lover")}</strong>}
                  btnBack={true}
                  // pagePadding={true}
                >
                  <WithAuth>
                    <BecomeLover paymentSuccess={handlePaymentSuccess} />
                  </WithAuth>
                </Layout>
              }
            />
            <Route
              path="/about"
              element={
                <Layout
                  title={<strong>{t("About")}</strong>}
                  btnBack={true}
                  pagePadding={true}
                >
                  <WithAuth>
                    <About />
                  </WithAuth>
                </Layout>
              }
            />
            <Route
              path="/account/complete-lover-profile"
              element={
                <Layout
                  title={<strong>{t("Complete Lover profile")}</strong>}
                  pagePadding={true}
                  btnBack={true}
                >
                  <WithAuth>
                    <CompleteLoverProfile editSuccess={handleEditSuccess} />
                  </WithAuth>
                </Layout>
              }
            />
            <Route
              path="/account/settings"
              element={
                <Layout
                  title={<strong>{t("Settings")}</strong>}
                  pagePadding={true}
                  btnBack={true}
                >
                  <WithAuth>
                    <Settings currentUser={currentUser} />
                  </WithAuth>
                </Layout>
              }
            />
            <Route
              path="/settings/account-preferences"
              element={
                <Layout
                  title={<strong>{t("Account preferences")}</strong>}
                  pagePadding={true}
                >
                  <WithAuth>
                    <AccountPreferences currentUser={currentUser} />
                  </WithAuth>
                </Layout>
              }
            />
            <Route
              path="/account/legal"
              element={
                <Layout
                  title={<strong>{t("Legal")}</strong>}
                  btnBack={true}
                  pagePadding={true}
                >
                  <WithAuth>
                    <Legal currentUser={currentUser} />
                  </WithAuth>
                </Layout>
              }
            />
            <Route
              path="/account/legal/terms-of-use"
              element={
                <Layout
                  title={<strong>{t("Terms of use")}</strong>}
                  btnBack={true}
                  pagePadding={true}
                >
                  <TermsOfUse currentUser={currentUser} />
                </Layout>
              }
            />
            <Route
              path="/account/legal/privacy-policy"
              element={
                <Layout
                  title={<strong>{t("Privacy policy")}</strong>}
                  btnBack={true}
                  pagePadding={true}
                >
                  <PrivacyPolicy currentUser={currentUser} />
                </Layout>
              }
            />
            <Route
              path="/account/legal/cookie-policy"
              element={
                <Layout
                  title={<strong>{t("Cookie policy")}</strong>}
                  btnBack={true}
                  pagePadding={true}
                >
                  <WithAuth>
                    <CookiePolicy currentUser={currentUser} />
                  </WithAuth>
                </Layout>
              }
            />
            <Route
              path="/account/legal/legal-notice"
              element={
                <Layout
                  title={<strong>{t("Legal notice")}</strong>}
                  btnBack={true}
                  pagePadding={true}
                >
                  <WithAuth>
                    <LegalNotice currentUser={currentUser} />
                  </WithAuth>
                </Layout>
              }
            />
            <Route
              path="/account/settings/notifications"
              element={
                <Layout
                  title={
                    <strong>
                      {t("Settings")}/{t("notifications")}
                    </strong>
                  }
                  btnBack={true}
                  pagePadding={true}
                >
                  <WithAuth>
                    <NotificationSettings currentUser={currentUser} />
                  </WithAuth>
                </Layout>
              }
            />
            <Route
              path="/account/settings/privacy-and-security"
              element={
                <Layout
                  title={<strong>{t("Privacy and security")}</strong>}
                  btnBack={true}
                  pagePadding={true}
                >
                  <WithAuth>
                    <PrivacyAndSecurity currentUser={currentUser} />
                  </WithAuth>
                </Layout>
              }
            />
            <Route
              path="/account/support-center"
              element={
                <Layout
                  title={<strong>{t("Support center")}</strong>}
                  btnBack={true}
                  pagePadding={true}
                >
                  <WithAuth>
                    <FAQ currentUser={currentUser} />
                  </WithAuth>
                </Layout>
              }
            />
            <Route
              path="/restricted-route"
              element={
                <Layout pagePadding={true}>
                  <RestrictedRoute currentUser={currentUser} />
                </Layout>
              }
            />
            <Route
              path="*"
              element={
                <Layout pagePadding={true}>
                  <Page404 currentUser={currentUser} />
                </Layout>
              }
            />
          </Routes>
        </HelmetProvider>
        <ModalPayment {...configModal}>
          <h3>{t("Subscription successfull")}</h3>
        </ModalPayment>
      </Suspense>
    </>
  );
}
